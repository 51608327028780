import React from "react";
import { graphql } from "gatsby";

import DevelopmentLendingSEO from "../../components/services/development-lending/developmentLendingSEO";
import TestimonialsCarousel from "../../components/shared/testimonials";
import BlogsSection from "../../components/shared/blogsSection";
import CustomPartners from "../../components/shared/partners/customPartners";
import { Query } from "@graphql-types";
import { Ctas, TextButtons, Header, IframeEmbed } from "@global";
import TabsLink from "@components/shared/sub/tabsLink";
interface Props {
  data: Query;
}

const DevelopmentLending = ({ data }: Props) => {
  if (data.sanityDevelopmentLendingPage == null) return null;

  const {
    lendingPartners,
    textButtons,
    ctas,
    title,
    header,
    iframeEmbed,
    topTextButtons,
  } = data.sanityDevelopmentLendingPage;
  const shared = data.sanitySharedSection;
  return (
    <div className="subpage-container">
      <DevelopmentLendingSEO />
      <Header title={title} headerData={header} />

      {shared && shared.servicesTabs && (
        <TabsLink
          tabs={shared.servicesTabs}
          pageColor={header?.headerColor}
          slug="/services/development-finance/"
        />
      )}
      {topTextButtons && <TextButtons data={topTextButtons} />}
      {ctas && <Ctas data={ctas} />}
      {iframeEmbed && <IframeEmbed data={iframeEmbed} />}
      {textButtons && <TextButtons data={textButtons} />}
      {lendingPartners && <CustomPartners data={lendingPartners} />}
      <TestimonialsCarousel color="red" />
      <BlogsSection title="Related Blogs" category="Development Finance" />
    </div>
  );
};

export default DevelopmentLending;

export const query = graphql`
  query {
    sanityDevelopmentLendingPage {
      title
      header {
        ...sanityHeader
      }
      textButtons {
        ...sanityTextButton
      }
      topTextButtons {
        ...sanityTextButton
      }
      iframeEmbed {
        ...sanityIframeEmbed
      }
      lendingPartners {
        title
        partnersLists {
          _key
          categoryTitle
          partners {
            _key
            partnerName
            partnerLogo {
              ...sanityFlightImage
            }
            partnerLink
          }
        }
        link {
          linkText
          url
        }
      }
      ctas {
        ...sanityCtas
      }
    }
    sanitySharedSection {
      servicesTabs {
        serviceTab {
          _key
          linkText
          url
        }
      }
    }
  }
`;
